import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: [],
  isLoading: false,
  questions: [],
  responseHistory: [],
  isSpeaking: false,
  latestQuestion: "",
  latestResponse: "",
  isListening: false,
  error: null,
  notice: null,
  transcript: ""
};

const speechSlice = createSlice({
  name: 'speech',
  initialState,
  reducers: {
    setTranscript: (state, action) => {
      state.transcript = action.payload;
    },
    setIsListening: (state, action) => {
      state.isListening = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setNotice: (state, action) => {
      state.notice = action.payload;
    },
    addQuestionAndResponse: (state, action) => {
      const { question, response } = action.payload;
      if (question && response) {
        state.questions.push(question);
        state.responseHistory.push(response);
        state.latestQuestion = question;
        state.latestResponse = response;
      }
    },
    resetAllData: (state) => {
      state.questions = [];
      state.responseHistory = [];
      state.latestQuestion = "";
      state.latestResponse = "";
    },
    setResponse: (state, action) => {
      state.response = action.payload;
      state.isSpeaking = false;
      state.isLoading = false;
      state.notice = null;
    }
  }
});

export const {
  setTranscript,
  setIsListening,
  setIsLoading,
  setError,
  setNotice,
  addQuestionAndResponse,
  resetAllData,
  setResponse
} = speechSlice.actions;

export default speechSlice.reducer; 