import axios from "axios";
import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Button } from "react-bootstrap";
import ChatLoader from "../Loader/ChatLoader";
import {
  setTranscript,
  setIsListening,
  setIsLoading,
  setError,
  setNotice,
  addQuestionAndResponse,
  resetAllData,
  setResponse
} from "../../redux/slices/speechSlice";

const API_KEY = "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";
const AUTO_STOP_TIMEOUT = 5000; 

const commands = [
  {
    command: '*',
    callback: (command) => {
      console.log('Speech detected:', command);
    }
  }
];

const DistaphoneNew = () => {
  const dispatch = useDispatch();
  const {
    isListening,
    isLoading,
    questions,
    responseHistory,
    error,
    notice,
    transcript: reduxTranscript
  } = useSelector((state) => state.speech);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition({ commands });

  const isProcessingRef = useRef(false);
  const hasProcessedRef = useRef(false);
  const autoStopTimeoutRef = useRef(null);

  useEffect(() => {
    if (transcript) {
      dispatch(setTranscript(transcript));
    }
  }, [transcript, dispatch]);

  useEffect(() => {
    const savedQuestions = localStorage.getItem("questions");
    const savedResponseHistory = localStorage.getItem("responseHistory");
    
    if (savedQuestions && savedResponseHistory) {
      try {
        const parsedQuestions = JSON.parse(savedQuestions);
        const parsedResponses = JSON.parse(savedResponseHistory);
        
        parsedQuestions.forEach((question, index) => {
          if (question && parsedResponses[index] && 
              typeof question === 'string' && typeof parsedResponses[index] === 'string' &&
              question.trim() !== '' && parsedResponses[index].trim() !== '') {
            dispatch(addQuestionAndResponse({
              question: question,
              response: parsedResponses[index]
            }));
          }
        });
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
        localStorage.removeItem("questions");
        localStorage.removeItem("responseHistory");
      }
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("questions", JSON.stringify(questions));
    localStorage.setItem("responseHistory", JSON.stringify(responseHistory));
  }, [questions, responseHistory]);

  useEffect(() => {
    if (transcript && listening) {
      dispatch(setNotice("Listening... Speak clearly into your microphone 🎤"));
    }
  }, [transcript, listening, dispatch]);

  // Auto-stop effect for when no speech is detected
  useEffect(() => {
    if (isListening && !transcript) {
      if (autoStopTimeoutRef.current) {
        clearTimeout(autoStopTimeoutRef.current);
      }
      autoStopTimeoutRef.current = setTimeout(() => {
        if (isListening && !transcript) {
          SpeechRecognition.stopListening();
          dispatch(setIsListening(false));
          dispatch(setNotice("No speech detected. Try speaking again! 🎤"));
        }
      }, AUTO_STOP_TIMEOUT);
    }
  }, [isListening, transcript, dispatch]);

  // Auto-stop effect for when speech stops for 10 seconds
  useEffect(() => {
    if (transcript && listening) {
      if (autoStopTimeoutRef.current) {
        clearTimeout(autoStopTimeoutRef.current);
      }
      autoStopTimeoutRef.current = setTimeout(() => {
        if (listening) {
          SpeechRecognition.stopListening();
          dispatch(setIsListening(false));
          dispatch(setIsLoading(true));
          if (reduxTranscript) {
            sendToChatGPT(reduxTranscript);
          } else {
            dispatch(setNotice("No speech detected. Try speaking again! 🎤"));
            dispatch(setIsLoading(false));
          }
        }
      }, AUTO_STOP_TIMEOUT);
    }
  }, [transcript, listening, reduxTranscript, dispatch]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (autoStopTimeoutRef.current) {
        clearTimeout(autoStopTimeoutRef.current);
      }
    };
  }, []);

  const toggleListening = useCallback(() => {
    if (isListening) {
      if (autoStopTimeoutRef.current) {
        clearTimeout(autoStopTimeoutRef.current);
      }
      SpeechRecognition.stopListening();
      dispatch(setIsListening(false));
      dispatch(setIsLoading(true));
      
      if (reduxTranscript) {
        sendToChatGPT(reduxTranscript);
      } else {
        dispatch(setNotice("No speech detected. Try speaking again! 🎤"));
        dispatch(setIsLoading(false));
      }
    } else {
      resetTranscript();
      hasProcessedRef.current = false;
      
      SpeechRecognition.startListening({ 
        continuous: true,
        language: 'en-US',
        interimResults: true
      });
      
      dispatch(setIsListening(true));
      dispatch(setNotice("Listening... Speak clearly into your microphone 🎤"));
    }
  }, [isListening, resetTranscript, reduxTranscript, dispatch]);

  const handleApiResponse = useCallback((responseText) => {
    dispatch(setResponse(responseText));
    dispatch(addQuestionAndResponse({
      question: reduxTranscript,
      response: responseText
    }));
    dispatch(setTranscript(""));
    isProcessingRef.current = false;
    hasProcessedRef.current = true;
  }, [reduxTranscript, dispatch]);

  const sendToChatGPT = async (transcript) => {
    if (!API_KEY) {
      dispatch(setError("API key not found"));
      return;
    }

    dispatch(setIsLoading(true));

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          max_tokens: 1000,
          messages: [{ role: "user", content: transcript }],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      if (response.status === 200) {
        handleApiResponse(response.data.choices[0].message.content);
      } else {
        throw new Error(`API call failed: ${response.status}`);
      }
    } catch (error) {
      console.error("Error calling ChatGPT API:", error);
      dispatch(setError("Failed to get response from ChatGPT"));
      dispatch(setIsLoading(false));
      isProcessingRef.current = false;
      hasProcessedRef.current = true;
    }
  };

  const handleResetAllData = useCallback(() => {
    localStorage.removeItem("questions");
    localStorage.removeItem("responseHistory");
    dispatch(resetAllData());
    hasProcessedRef.current = false;
  }, [dispatch]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  if (!isMicrophoneAvailable) {
    return <span>Please enable microphone access in your browser settings.</span>;
  }

  return (
    <>
      <div className="dicta-container">
        <p className="microphone margin-cls">
          Microphone: {isListening ? "on" : "off"}
        </p>
        <div className="d-flex justify-content-between strt-rst-btn-wrapper">
          <div></div>
          <Button
            variant={isListening ? "danger" : "success"}
            onClick={toggleListening}
            style={{ marginRight: "10px" }}
            className="ms-auto speak-disabled"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-megaphone"
              viewBox="0 0 16 16"
            >
              <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
            </svg>
          </Button>
          <Button
            variant="info"
            onClick={handleResetAllData}
            className="reset-btn ms-auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
              <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
            </svg>
          </Button>
        </div>
        <p className="transcript-btn margin-cls">{reduxTranscript}</p>
        {error && (
          <div className="error-message text-danger">{error}</div>
        )}
        {notice && (
          <div className="notice-message text-warning">{notice}</div>
        )}
      </div>

      <div className="chat-container">
        {isLoading ? (
          <ChatLoader />
        ) : (
          <>
            {questions.length === 0 ? (
              <div className="welcome-message">
                <h3>Welcome! 👋</h3>
                <p>Ready to chat? Just click the microphone button below and start speaking! I'm here to help answer your questions. 🎤✨</p>
              </div>
            ) : (
              questions.map((question, index) => (
                <div className="conversation" key={index}>
                  <div className="user-question">{question}</div>
                  <div className="bot-answer">{responseHistory[index]}</div>
                </div>
              ))
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DistaphoneNew;
